<template>
  <div class="home-page">
    <!-- 顶部导航 -->
    <header class="header">
      <div class="logo">
        <!--<img src="https://www.sslphp.com/storage/upload/20240726/66a287e9670a2.svg" alt="SSLPHP.COM" />-->
        <img :src="seoOptions.site_logo" alt="Site Logo" />
        <span>{{ seoOptions.logotitle }}</span>
      </div>
      <nav class="nav">
        <el-link type="primary" @click="handleHistoryOrderClick">历史订单</el-link>
        <!--<el-link type="primary">工具箱</el-link>-->
      </nav>
    </header>

    <!-- 中间内容 -->
    <main class="main-content">
      <p>信任，从SSL证书开始</p>
      <div class="input-group">
        <el-input
          v-model="domain"
          placeholder="请输入域名，如：example.com 或 *.example.com"
          class="custom-input"
        ></el-input>
        <el-button type="primary" @click="handleGenerateSSL">生成SSL证书</el-button>
      </div>
      <div class="product-list">
        <div class="product-container">
          <el-card
            v-for="product in products"
            :key="product.id"
            @click="handleProductClick(product)"
            :class="['product-card', { 'selected': selectedProduct && selectedProduct.id === product.id }]"
          >
            <img :src="product.ssl_logo" class="product-logo" />
            <div class="product-details">
              <div class="product-name">{{ product.name }}</div>
              <div class="product-info">SSL类型: {{ product.ssl_type }}</div>
              <div class="product-info">域名数量: {{ product.number == 1 ? '单域名' : '通配符' }}</div>
              <div class="product-info">证书时长: {{ product.certificate_duration }}年</div>
              <div class="product-price">
                ¥{{ product.price }} <span>/年</span>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </main>

    <!-- 底部版权信息 -->
    <footer class="footer">
      <p>{{ seoOptions.copyright }}</p>
      <p>{{ seoOptions.site_icp }}</p>
      <p>{{ seoOptions.site_ga }}</p>
    </footer>

    <!-- 产品抽屉 -->
    <ProductDrawer
      v-if="drawerType === 'ProductDrawer'"
      v-model:visible="drawerVisible.product"
      :product="selectedProduct"
      :domain="domain"
      @submitOrder="handleSubmitOrder"
    />
    <ProductDrawerRss
      v-else-if="drawerType === 'ProductDrawerRss'"
      v-model:visible="drawerVisible.product"
      :product="selectedProduct"
      :domain="domain"
      @submitOrder="handleSubmitOrder"
    />
    
    <!-- 新的历史订单抽屉 -->
    <HistoryOrderDrawer
      v-model:visible="drawerVisible.history"
    />




 <!-- 漂浮的客服二维码 -->
 <div v-if="isQRCodeVisible" class="floating-qrcode">
  <span class="close-btn" @click="hideQRCode">×</span>
  <img :src="seoOptions.site_icon" alt="客服二维码" />
  <p>扫码添加客服微信咨询</p>
</div>




  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import ProductDrawer from "@/components/ProductDrawer.vue";
import ProductDrawerRss from "@/components/ProductDrawerRss.vue";
import HistoryOrderDrawer from "@/components/HistoryOrderDrawer.vue"; // 引入新的组件
import api from "@/api";
import { useHead } from '@vueuse/head';

export default {
  name: "HomePage",
  components: {
    ProductDrawer,
    ProductDrawerRss,
    HistoryOrderDrawer, // 注册新的组件
  },
  setup() {
    const isQRCodeVisible = ref(true);
    const products = ref([]);
    const domain = ref("");
    const drawerVisible = ref({
      product: false,
      history: false,
    });

    const hideQRCode = () => {
  isQRCodeVisible.value = false;
};




    const selectedProduct = ref(null);
    const drawerType = ref("ProductDrawerRss"); // 默认抽屉类型

  const seoOptions = ref({
    site_title: '默认网站标题',
    description: '默认描述',
    keywords: '默认关键词'
  });

  const loadProducts = async () => {
    try {
      const response = await api.getProducts();
      products.value = response.data;
      if (products.value.length > 0) {
        selectedProduct.value = products.value[0];
      }
    } catch (error) {
      console.error("获取产品列表错误:", error);
    }
  };

  const fetchSiteOptions = async () => {
      try {
        const response = await api.fetchSiteOptions(); // 调用在 api.js 中新定义的方法
        seoOptions.value = response.data; // 根据实际数据结构更新
      } catch (error) {
        console.error('Failed to fetch site options', error);
      }
    };

    onMounted(async () => {
      await fetchSiteOptions();
      await loadProducts();
      useHead({
        title: seoOptions.value.site_title || 'Home - 你的网站标题',
        meta: [
          { name: 'description', content: seoOptions.value.description || '这是主页的描述' },
          { name: 'keywords', content: seoOptions.value.keywords || '主页关键词1, 主页关键词2' }
        ]
      });
    });





    const handleProductClick = (product) => {
      selectedProduct.value = product;
      console.log("handleProductClick:", product);
    };

    const handleGenerateSSL = () => {
      if (!domain.value) {
        alert("请输入域名");
        return;
      }

      const domainPattern =
        selectedProduct.value.number == 1
          ? /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          : /^\*\.[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!domainPattern.test(domain.value)) {
        alert("请输入正确的域名格式");
        return;
      }

      // 根据产品的 ssl_product_id 决定打开哪个抽屉
      drawerType.value =
        selectedProduct.value.ssl_product_id === "197"
          ? "ProductDrawer"
          : "ProductDrawerRss";

      drawerVisible.value.product = true;
      console.log(
        "handleGenerateSSL:",
        domain.value,
        drawerVisible.value,
        drawerType.value
      );
    };

    const handleSubmitOrder = async (orderData) => {
      try {
        await api.createOrder(orderData);
        // 处理成功逻辑
        drawerVisible.value.product = false;
      } catch (error) {
        console.error("提交订单错误:", error);
      }
    };

    const handleHistoryOrderClick = () => {
      drawerVisible.value.history = true;
      console.log("历史订单点击");
    };





    onMounted(loadProducts);

    return {
      products,
      domain,
      drawerVisible,
      selectedProduct,
      drawerType,
      handleProductClick,
      handleGenerateSSL,
      handleSubmitOrder,
      handleHistoryOrderClick,
      seoOptions,
      isQRCodeVisible,
      hideQRCode,
    };
  },
};
</script>




<style scoped>
.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  min-height: 100vh;
  overflow-x: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: white;
  border-bottom: 1px solid white;
  box-sizing: border-box;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 35px;
  margin-right: 10px;
}

.logo span {
  font-size: 24px;
  color: #333;
}

.nav {
  display: flex;
  align-items: center;
}

.nav .el-link {
  margin-left: 20px;
}

.main-content {
  flex: 1;
  padding: 40px 20px;
  box-sizing: border-box;
  width: 100%;
}

.main-content p {
  margin-bottom: 20px;
  font-size: 32px;
  color: #333;
}

.input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px !important;
  flex-wrap: nowrap;
  border: 1px solid #d3e1ec;
  border-radius: 0px;
  padding: 14px 24px 14px 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  max-width: 780px;
  margin: 0 auto; /* Centering the input group */
}

.custom-input >>> .el-input__wrapper {
  border: none;
  box-shadow: none;
  height: 40px; /* 根据需要调整高度 */
  font-size: 16px;
}

.custom-input {
  flex: 1;
  height: 40px;
  padding: 0;
}

.input-group .el-button {
  height: 40px;
  background-color: #326ef4;
  color: white;
  border-radius: 0;
  padding: 0 20px;
  font-size: 16px;
}

.product-list {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Added gap to ensure proper spacing */
}

.product-card {
  cursor: pointer;
  text-align: left;
  transition: box-shadow 0.3s;
  border: 1px solid #e0e6ed;
  border-radius: 8px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 280px; /* Adjusted width to ensure content fits */
}

.product-card.selected {
  border-color: #326ef4;
}

.product-card.selected .product-price {
  color: #326ef4;
}

.product-logo {
  width: 150px;
  height: auto;
  margin-bottom: 10px;
}

.product-details {
  font-size: 14px;
  color: #666;
  white-space: nowrap; /* Ensure no line breaks */
}

.product-price {
  font-size: 18px;
  color: red;
  margin-top: 10px;
  font-weight: bold;
}

.product-price span {
  color: black;
  font-weight: normal;
}

.footer {
  width: 100%;
  padding: 20px;
  background-color: white;
  text-align: center;
  font-size: 14px;
  color: #666;
  border-top: 1px solid white;
  box-sizing: border-box;
}

/*二维码 */


.floating-qrcode {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #326ef4;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.floating-qrcode img {
  width: 200px; /* 设置二维码的宽度 */
  height: 200px; /* 设置二维码的高度 */
  margin-bottom: 10px;
  border-radius: 8px;
}

.floating-qrcode .close-btn {
  position: absolute;
  top: -40px;
  right: -15px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  font-size: 18px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  z-index: 10; /* 确保它在其他元素之上 */
}


.floating-qrcode p {
  margin: 0;
  font-size: 14px;
}

</style>
