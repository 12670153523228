import axios from 'axios';

// 创建 axios 实例
const api = axios.create({
    baseURL: 'https://agent.dxdu.cn',
    timeout: 100000,
});

// 请求拦截器，添加授权头
api.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// 获取产品列表的方法
async function getProducts() {
    try {
        const response = await api.get('/api/home/mall');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch products', error);
        throw error;
    }
}

// 创建订单的方法
async function createOrder(orderData) {
    try {
        const response = await api.post('/api/home/orders', orderData);
        return response.data;
    } catch (error) {
        console.error('Failed to create order', error);
        throw error;
    }
}

// 查询订单状态的方法
async function checkOrderStatus(orderNumber) {
    try {
        const response = await api.get('/api/home/checkOrderStatus', { params: { order_number: orderNumber } });
        return response.data;
    } catch (error) {
        console.error('Failed to check order status', error);
        throw error;
    }
}



// 生成证书的方法
async function generateCertificate(orderData) {
    try {
        const response = await api.post('/api/home/generate-certificate', orderData);
        return response.data;
    } catch (error) {
        console.error('Failed to generate certificate', error);
        throw error;
    }
}





// 验证域名的方法
async function verifyDomain(orderData) {
    try {
        const response = await api.post('/api/home/verify-domain', orderData);
        return response.data;
    } catch (error) {
        console.error('Failed to verify domain', error);
        throw error;
    }
}

// 查询接口订单状态的方法
async function queryInterfaceOrderStatus(certbaseOrder) {
    try {
        const response = await api.get('/api/home/queryInterfaceOrderStatus', { params: { certbase_order: certbaseOrder } });
        return response.data;
    } catch (error) {
        console.error('Failed to query interface order status', error);
        throw error;
    }
}

// 下载证书的方法
async function downloadCertificate(certbaseOrder) {
    try {
        const response = await api.get('/api/home/downloadCertificate', { params: { certbase_order: certbaseOrder } });
        return response.data;
    } catch (error) {
        console.error('Failed to download certificate', error);
        throw error;
    }
}



/*芮城*/

// 获取产品列表的方法
async function rssGetProducts() {
    try {
        const response = await api.get('/api/home/rc/mall');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch products', error);
        throw error;
    }
}

// 创建订单的方法
async function rssCreateOrder(orderData) {
    try {
        const response = await api.post('/api/home/rc/orders', orderData);
        return response.data;
    } catch (error) {
        console.error('Failed to create order', error);
        throw error;
    }
}

// 查询订单状态的方法
async function rssCheckOrderStatus(orderNumber) {
    try {
        const response = await api.get('/api/home/rc/checkOrderStatus', { params: { order_number: orderNumber } });
        return response.data;
    } catch (error) {
        console.error('Failed to check order status', error);
        throw error;
    }
}

// 生成证书的方法
async function rssGenerateCertificate(orderData) {
    try {
        const response = await api.post('/api/home/rc/generate-certificate', orderData);
        return response.data;
    } catch (error) {
        console.error('Failed to generate certificate', error);
        throw error;
    }
}

// 验证域名的方法
async function rssVerifyDomain(orderData) {
    try {
        const response = await api.post('/api/home/rc/verify-domain', orderData);
        return response.data;
    } catch (error) {
        console.error('Failed to verify domain', error);
        throw error;
    }
}






// 收集证书的方法
async function rssCollect(orderData) {
    try {
        const response = await api.post('/api/home/rc/collect', orderData); // 确保请求体使用 order_number
        return response.data;
    } catch (error) {
        console.error('Failed to collect certificate', error);
        throw error;
    }
}

// 查询历史订单的方法
async function searchOrderHistory(domain, email) {
    try {
        const response = await api.post('/api/apicode/ordr/searchOrder', { domain, email });
        return response.data;
    } catch (error) {
        console.error('Failed to search order history', error);
        throw error;
    }
}


// 获取网站SEO选项的方法
async function fetchSiteOptions() {
    try {
        const response = await api.get('/api/site/options');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch site options', error);
        throw error;
    }
}


export default {
    getProducts,
    createOrder,
    checkOrderStatus,
    generateCertificate,
    verifyDomain,
    queryInterfaceOrderStatus,
    downloadCertificate,
    rssGetProducts,
    rssCreateOrder,
    rssCheckOrderStatus,
    rssGenerateCertificate,
    rssVerifyDomain,
    rssCollect,
    searchOrderHistory, // 添加历史订单查询的方法
    fetchSiteOptions, // 添加获取SEO选项的方法
};