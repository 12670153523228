/*
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

createApp(App).use(router).mount('#app');  */

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElIconModules from '@element-plus/icons-vue';

const app = createApp(App);
const head = createHead();

// 全局注册Element Plus图标组件
for (const iconName in ElIconModules) {
    app.component(iconName, ElIconModules[iconName]);
}


app.use(router);
app.use(head);
app.use(ElementPlus);
app.mount('#app');